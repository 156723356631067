.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pageBlock-quote {
  color: darkgrey;
  font-family: serif;
  font-style: italic;
  font-size: 20px;
}

.pageBlock-paragraph {
  color: black;
  font-family: sans-serif;
  font-size: 16px;
}

.pageBlock-heading {
  color: black;
  font-family: sans-serif;
  font-size: 24px;
}

.pageBlock-heading2 {
  color: black;
  font-family: sans-serif;
  font-size: 16px;
}

.draftJsDisplayInTable {
  display: inline-block;
  margin-right: 3px;
  white-space: nowrap;
  overflow-wrap: normal;
  word-break: keep-all;
}

.draftJsDisplayInTable * {
  margin: 0 !important;
}

#portal-root {
  position: relative;
  z-index: 99999;
}
